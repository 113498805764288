@import '../../Assets/SCSS/fonts.scss';

* {
    outline: none !important;
}
a {
    text-decoration: none !important;
}

h5 {
    font-family: 'Corporate A Cond Pro Regular'
}

.container {
    padding: 0px !important;
}
.main-container{
    background-color: #fff;
}

.inline-photo {
    border: 1em solid #fff;
    border-bottom: 4em solid #fff;
    border-radius: .25em;
    box-shadow: 1em 1em 2em .25em rgba(0,0,0,.2);
    margin: 2em auto;
    opacity: 0;
    transform: translateY(4em) rotateZ(-5deg);
    transition: transform 4s .25s cubic-bezier(0,1,.3,1),
                opacity .3s .25s ease-out;
    max-width: 600px;
    width: 90%;
    will-change: transform, opacity;
  }
  
  .inline-photo.is-visible {
    opacity: 1;
    transform: rotateZ(-2deg);
  }

  .accept-cookie {
    position: fixed;
    text-align: left; 
    bottom: 0px;
    z-index: 999999;
    left: 0px;
    padding: 20px;
    background: #000000c4;
    color: #fff;
    width: 100%;

    p {
        width: 80%;
        float: left;
        margin-bottom: 0px;
    }
    button {
        float: right;
        border: 0px;
        background: #fff;
        padding: 5px 20px;
        border-radius: 4px;
        position: relative;
        top: -5px;
    }
  }

  #openCookie {
    margin-right: 10px !important;
    background: #008DFC;
    color: #fff;
    float: right;
    padding: 6px 20px;
    font-size: 16px;
    opacity: 1;
    border-radius: 5px;
    -webkit-appearance: none;
  }

  .announcements {

    .slick-track {

        .announcement {

            h5 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'MB Corpo A Screen Title Condensed';
            }
        }
    }
  }

@media only screen and (max-width: 768px) {

    .accept-cookie {

        p {
            width: 70%;
        }
        #openCookie {
            margin-top: 10px;
            margin-right: 0px !important;
            margin-left: 10px !important;
        }
    }
    .SubPosts .subposts {
        margin-left: 0px;
        margin-right: 0px !important;
    }
    .SubPosts .subposts .subcategory-image {
        height: auto !important;

        img {
            width: 100%;
        }
    }
    .sticky {
        position: initial !important;
    }

    .Menu nav.d-block {
        transition: 6000ms display ease-in-out !important;
        position: fixed !important;
        top: 0px;
        left: 0px;
        height: 100%;
        z-index: 999999999 !important;
        width: 249px !important;
    }

    .menuActive {
        margin-right: -260px;
        padding-left: 250px;

        .menuNavButton {
            background: url(../../Assets/img/cancel-white.svg) !important;
            background-size: 20px !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            height: 28px !important;
            margin-bottom: 0px !important;
        }
    }
    .AnnouncementSummary .announcement img {
        float: none !important;
        max-height: 115px !important;
        margin-bottom: 16px !important;
    }

    
}

@media only screen and (max-width: 992px) and (min-width: 768px) {

    .accept-cookie {

        p {
            width: 70%;
        }
        #openCookie {
            margin-top: 10px;
            margin-right: 0px !important;
            margin-left: 10px !important;
        }
    }
    .SubPosts .subposts {
        margin-left: 0px;
        margin-right: 0px !important;
    }
    .SubPosts .subposts .subcategory-image {
        height: auto !important;

        img {
            width: 100%;
        }
    }
    .sticky {
        position: initial !important;
    }

    .Menu nav.d-block {
        transition: 6000ms display ease-in-out !important;
        position: fixed !important;
        top: 0px;
        left: 0px;
        height: 100%;
        z-index: 999999999 !important;
        width: 249px !important;
        >a{
            text-align: left;
        }
    }

    .menuActive {
        margin-right: -260px;
        padding-left: 250px;

        .menuNavButton {
            background: url(../../Assets/img/cancel-white.svg) !important;
            background-size: 20px !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            height: 28px !important;
            margin-bottom: 0px !important;
        }
    }
    .AnnouncementSummary .announcement img {
        float: none !important;
        max-height: 115px !important;
        margin-bottom: 16px !important;
    }

    
}




.noInspect {
    padding: 0px !important;
    background: transparent !important;
    font-size: 13px !important;
}
@import url(./Assets/SCSS/fonts.scss);

@font-face {
  font-family: "icomoon";
  src: url("Assets/SCSS/Fonts/icomoon.eot");
  src: url("Assets/SCSS/Fonts/icomoon.eot?zdwhzk#iefix") format("embedded-opentype"),
    url("Assets/SCSS/Fonts/icomoon.ttf?zdwhzk") format("truetype"),
    url("Assets/SCSS/Fonts/icomoon.woff?zdwhzk") format("woff"),
    url("Assets/SCSS/Fonts/icomoon.svg?zdwhzk#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back_thick:before {
  content: "\e900";
}

.icon-back_thin:before {
  content: "\e901";
}

.icon-forward_thick:before {
  content: "\e902";
}

.icon-forward_thin:before {
  content: "\e903";
}

.icon-additional_options_flat:before {
  content: "\e904";
}

.icon-additional_options_line:before {
  content: "\e905";
}

.icon-download_flat:before {
  content: "\e906";
}

.icon-download_line:before {
  content: "\e907";
}

.icon-download_printed_version_form_flat:before {
  content: "\e908";
}

.icon-download_printed_version_form_line:before {
  content: "\e909";
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "MB Corpo A Title Cond";
}

i,
p,
span,
div,
a {
  font-family: "MB Corpo S Text";
}

.title-desc,
b {
  font-family: "MB Corpo S Title";
}

.Loader {
  position: fixed;
  z-index: 999999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #fff;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }
}

body {
  overflow-x: hidden;
}

table {
  &.downloadable-content {
    thead {
      tr {
        th {
          background: transparent;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 20px;

          .doc,
          .excel,
          .pdf,
          .zip,
          .ppt,
          .jpg {
            width: 23px;
            height: 25px;
            display: block;
            background-image: url(./Assets/img/001_doc.png);
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
          }

          .excel {
            background-image: url(./Assets/img/002_xls.png);
          }

          .pdf {
            background-image: url(./Assets/img/005_pdf.png);
          }

          .jpg {
            background-image: url(./Assets/img/004_jpg.png);
          }

          .ppt {
            background-image: url(./Assets/img/003_ppt.png);
          }

          .zip {
            background-image: url(./Assets/img/006_zip.png);
          }

          .download-button {
            display: inline-block;
            float: right;
            margin-right: 20px;
            width: 15px;
            height: 16px;
            background: url(./Assets/img/download.png);
            border: 0px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            text-indent: 100px;
          }
        }
      }
    }
  }
}

.popup-content {
  background: transparent !important;
  border: 0px !important;

  a.close {
    cursor: pointer;
  }

  .open-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    transition: 600ms all ease !important;

    .Menu {
      nav {
        div.dropdown-item-list {
          width: 100%;
          border-bottom: #5c5c5c 1px solid !important;

          .dropdown-list {
            background-color: #282828 !important;
            position: relative !important;
            left: 0px;
            top: 0px !important;
            background-size: 20% !important;

            a {
              border-bottom: rgb(226, 182, 182) 1px solid !important;
            }

            div.dropdown-list {
              background-color: #353535!important;
              left: 0px !important;
              width: 100%;
              margin: 0px !important;
              box-shadow: 0px 0px 0px 0px #000;
              background-size: 20% !important;

              a {
                border-bottom: rgb(185, 167, 167) 1px solid !important;
              }
            }
          }
          .active-mobilemenu{
            animation: slideInLeft ease-in-out 650ms !important;
            -webkit-animation: slideInLeft ease 650ms !important;
            -moz-animation: slideInLeft ease 650ms !important;
            -o-animation: slideInLeft ease 650ms !important;
            -ms-animation: slideInLeft ease 650ms !important;
          }
        }
        >a {
          border-bottom: #5c5c5c 2px solid !important;
        }
      }

      .Language,
      .Search {}
    }

    .Footer {
      background-size: auto 48%;
      background-position: -150px bottom;

      .Menu {
        button {
          display: none !important;
        }

        nav {
          display: block !important;
          background: transparent;
          margin-bottom: 10px;

          div.dropdown-item-list {
            border-bottom: none !important;
          }

          >a {
            border-bottom: none !important;
          }
        }
      }
    }

    .SubCategories {
      .subcategory {
        .text {
          padding: 20px 40px;

          h5 {
            width: 100%;
            float: right;
            height: 69px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.Footer {
  .dropdownActivate {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .Header .logo-section .mercedes-turk {
    width: 160px !important;
    background-size: 100%;
  }

  .Header .logo-section .mercedes-logo {
    width: 51px !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .AnnouncementDetails {
    padding: 20px;
  }

  .aboutus,
  .mainposts,
  .announcementslider,
  .Subpage {
    padding-left: 20px;
    padding-right: 20px;
  }

  .announcementslider {
    img {
      width: 100%;
    }
  }

  .slick-arrow {
    margin-top: 53px;
  }

  .search-details {
    .search-input {
      width: 70%;
      left: 50%;
      margin-left: -35%;
    }
  }

  .SliderComp .slick-list .slick-slide div>div div.desc {
    margin-left: 20px !important;
  }

  .SliderComp .slick-list .slick-slide div>div div.desc div.title {
    font-size: 12px !important;
  }

  .SubCategories .subcategory .subcategory-image {
    height: auto !important;
  }
}

.Subpage {
  .details {
    ul {
      margin-left: 35px;
    }

    .subDetailsp {
      color: #000;
      img {
        margin-right: 15px;
        margin-left: 15px;
      }
    }
  }
}

.recalltext {}

.MapFilterDetails {
  .inputs {
    display: inline-block;
    width: 100%;

    label {
      width: 100%;
      font-weight: bold;
      font-family: "Corporate A Cond Pro Regular";
    }

    .checkbox {
      float: left;
      margin-right: 15px;

      label {
        width: initial;
        font-size: 16px;
        margin-left: 10px;
        line-height: 30px;
        font-weight: 400;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      }
    }

    input {
      float: left;
      margin-top: 9px;
    }
  }
}


@media only screen and (max-width: 768px) {
  .Subpage .info {
    margin-top: 10px !important;
  }


  .MainPosts p {
    height: auto !important;
  }

  .mainmap {
    background-position: left !important;
  }

  .MainCategories .category img {
    width: 100% !important;
  }

  .MainPosts span.post-title i {
    font-size: 25px !important;
  }

  .MainPosts p.post-desc {
    font-size: 14px !important;
  }

  .mainposts img {
    width: 100% !important;
    height: auto !important;
    min-height: auto !important;
  }

  .accept-cookie #openCookie {
    margin-top: 0px !important;
    margin-right: 10px !important;
  }

  .accept-cookie p {
    width: 100% !important;
    margin-bottom: 15px;
  }
}

.brochure-img {
  margin: 0px !important;
}

.menuActive {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  html {
    transition: 600ms all ease !important;

    .Menu {
      nav {
        div.dropdown-item-list {
          width: 100%;
          border-bottom: #5c5c5c 1px solid !important;  

          .dropdown-list {        
            background-color: #282828  !important;
            position: relative !important;
            left: 0px;
            top: 0px !important;
            background-size: 20% !important;

            a {
              border-bottom: rgb(226, 182, 182) 1px solid !important;
            }

            div.dropdown-list {
              background-color: #353535!important;
              left: 0px !important;
              width: 100%;
              margin: 0px !important;
              box-shadow: 0px 0px 0px 0px #000;
              background-size: 20% !important;

              a {
                border-bottom: rgb(185, 167, 167) 1px solid !important;
              }
            }
          }
          .active-mobilemenu{
            animation: slideInLeft ease-in-out 450ms !important;
            -webkit-animation: slideInLeft ease 450ms !important;
            -moz-animation: slideInLeft ease 450ms !important;
            -o-animation: slideInLeft ease 450ms !important;
            -ms-animation: slideInLeft ease 450ms !important;
          }
        }

        >a {
          border-bottom: #5c5c5c 2px solid !important;
        }
      }

      .Language,
      .Search {}
    }

    .Footer {
      background-size: auto 48%;
      background-position: -150px bottom;

      .Menu {
        button {
          display: none !important;
        }

        nav {
          display: block !important;
          background: transparent;
          margin-bottom: 10px;

          div.dropdown-item-list {
            border-bottom: none !important;
          }

          >a {
            border-bottom: none !important;
          }
        }
      }
    }

    .SubCategories {
      .subcategory {
        .text {
          padding: 20px 40px;

          h5 {
            width: 100%;
            float: right;
            height: 100px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
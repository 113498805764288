@font-face {
    font-family: 'Corporate S';
    src: url('./Fonts/CorporateS-ExtraBoldItalic.woff2') format('woff2'),
        url('./Fonts/CorporateS-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate S';
    src: url('./Fonts/CorporateS-LightItalic.woff2') format('woff2'),
        url('./Fonts/CorporateS-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate A Cond Pro Regular';
    src: url('./Fonts/CorporateACondPro-Regular.woff2') format('woff2'),
        url('./Fonts/CorporateACondPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate A Condensed';
    src: url('./Fonts/CorporateACon-Reg.woff2') format('woff2'),
        url('./Fonts/CorporateACon-Reg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate A';
    src: url('./Fonts/CorporateA-Regular.woff2') format('woff2'),
        url('./Fonts/CorporateA-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate A Cond Pro Light';
    src: url('./Fonts/CorporateACondPro-Light.woff2') format('woff2'),
        url('./Fonts/CorporateACondPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CorporateAConOM';
    src: url('./Fonts/CorporateAConOM.woff2') format('woff2'),
        url('./Fonts/CorporateAConOM.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate S';
    src: url('./Fonts/CorporateS-Italic.woff2') format('woff2'),
        url('./Fonts/CorporateS-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate S';
    src: url('./Fonts/CorporateS-Bold.woff2') format('woff2'),
        url('./Fonts/CorporateS-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate S';
    src: url('./Fonts/CorporateS-DemiItalic.woff2') format('woff2'),
        url('./Fonts/CorporateS-DemiItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate S';
    src: url('./Fonts/CorporateS-Light.woff2') format('woff2'),
        url('./Fonts/CorporateS-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Corporate S';
    src: url('./Fonts/CorporateS-Regular.woff2') format('woff2'),
        url('./Fonts/CorporateS-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CorporateS';
    src: url('./Fonts/CorporateS-Demi.woff2') format('woff2'),
        url('./Fonts/CorporateS-Demi.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MB Corpo S Title';
    src: url('./Fonts/MBCorpoSTitle-Bold.eot');
    src: url('./Fonts/MBCorpoSTitle-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Fonts/MBCorpoSTitle-Bold.woff2') format('woff2'),
        url('./Fonts/MBCorpoSTitle-Bold.woff') format('woff'),
        url('./Fonts/MBCorpoSTitle-Bold.ttf') format('truetype'),
        url('./Fonts/MBCorpoSTitle-Bold.svg#MBCorpoSTitle-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MB Corpo A Title Cond';
    src: url('./Fonts/MBCorpoATitleCond-Regular.eot');
    src: url('./Fonts/MBCorpoATitleCond-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Fonts/MBCorpoATitleCond-Regular.woff2') format('woff2'),
        url('./Fonts/MBCorpoATitleCond-Regular.woff') format('woff'),
        url('./Fonts/MBCorpoATitleCond-Regular.ttf') format('truetype'),
        url('./Fonts/MBCorpoATitleCond-Regular.svg#MBCorpoATitleCond-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MB Corpo S Title Bold Condensed';
    src: url('./Fonts/MBCorpoSTitle-CondBold.otf') format('opentype'),
    url('./Fonts/MBCorpoSTitle-CondBold.woff2') format('woff2'),
    url('./Fonts/MBCorpoSTitle-CondBold.woff') format('woff'),
    url('./Fonts/MBCorpoSTitle-CondBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MB Corpo S Text';
    src: url('./Fonts/MBCorpoSText-Regular.eot');
    src: url('./Fonts/MBCorpoSText-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Fonts/MBCorpoSText-Regular.woff2') format('woff2'),
        url('./Fonts/MBCorpoSText-Regular.woff') format('woff'),
        url('./Fonts/MBCorpoSText-Regular.ttf') format('truetype'),
        url('./Fonts/MBCorpoSText-Regular.svg#MBCorpoSText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MB Corpo A Screen Title Condensed';
    src: url('./Fonts/MBCorpoAScreen1Cond-Regular.otf') format('opentype'),
    url('./Fonts/MBCorpoAScreen1Cond-Regular.woff2') format('woff2'),
    url('./Fonts/MBCorpoAScreen1Cond-Regular.woff') format('woff'),
    url('./Fonts/MBCorpoAScreen1Cond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
